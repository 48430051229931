import axios from "axios";

const client = axios.create({
    baseURL: 'https://rasa.singidunum.ac.rs/upis/api',
    withCredentials: false,
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    },
    validateStatus: function (status) {
        return status === 200 || status === 204
    }
});

export class MainService {
    public static retrieveAll(page: number) {
        return client.get(`/event?page=${page}&size=12&sort=id,desc`);
    }

    public static retrieveById(id: number) {
        return client.get('/event/' + id);
    }

    public static retrieveIntentStats() {
        return client.get('/event/stats/intent');
    }

    public static retrieveFallbackEvents(page: number) {
        return client.get(`/event/intent/nlu_fallback?page=${page}&size=12&sort=id,desc`)
    }
}