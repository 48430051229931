import {createRouter, createWebHistory, RouteRecordRaw} from 'vue-router'
import HomeView from "@/views/HomeView.vue";
import TableView from "@/views/TableView.vue";
import EventView from "@/views/EventView.vue";
import FallbackView from "@/views/FallbackView.vue";
import UnauthorizedView from "@/views/UnauthorizedView.vue";
import NotFoundView from "@/views/NotFoundView.vue";

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        name: 'home',
        component: HomeView,
        meta: {
            title: 'Home'
        }
    },
    {
        path: '/table',
        name: 'table',
        component: TableView,
        meta: {
            title: 'Table'
        }
    },
    {
        path: '/event/:id',
        name: 'event',
        component: EventView,
        props: true,
        meta: {
            title: 'Event'
        }
    },
    {
        path: '/fallback',
        name: 'fallback',
        component: FallbackView,
        meta: {
            title: 'Fallback'
        }
    },
    {
        path: '/Unauthorized',
        name: 'unauthorized',
        component: UnauthorizedView,
        meta: {
            title: 'Unauthorized'
        }
    },
    {
        path: "/:pathMatch(.*)*",
        name: "not-found",
        meta: {
            title: 'Not Found'
        },
        component: NotFoundView
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

router.beforeEach((to, from, next) => {
    if (to.meta.title)
        document.title = `${to.meta.title} :: Rasa Analitika`;
    next();
})

export default router
